<template>
  <transition name="slide">
    <div class="messages-wrapper" :class="'message-' + type" v-if="show">
      <div class="message-content" v-bind:class="{'closable': close}">
        <p>{{ message }}</p>
      </div>
      <div class="message-close" v-if="close" @click="Hide"></div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'Messages',
    data() {
      return {
        show: false,
        type: null,
        message: null,
        persist: false,
        close: false,
        delay: null,
        hide: 0
      }
    },
    created() {
      this.$eventHub.$on('ShowMessages', (data = {}) => {
        this.show = true;
        this.hide = data.hide;
        this.close = data.persist ? false : data.close;
        this.persist = data.persist;
        this.type = data.type || 'success';
        this.message = data.message || 'The message goes here :D';
        window.addEventListener('keydown', this.Esc);
        clearTimeout(this.delay);
        if (!this.hide && !this.close) {
          switch (this.type) {
            case 'error':
              this.hide = 8000;
              break;
            case 'warning':
              this.hide = 5000;
              break;
            case 'success':
              this.hide = 2000;
          }
        }
        if ((this.close && !this.hide) || this.persist) return;
        this.delay = setTimeout(this.Hide, this.hide);
      });
      this.$eventHub.$on('HideMessages', this.Hide);
    },
    destroyed() {
      window.removeEventListener('keydown', this.Esc);
    },
    methods: {
      Hide() {
        this.show = false;
        clearTimeout(this.delay);
        window.removeEventListener('keydown', this.Esc);
      },
      Around(e) {
        if (e.target.classList.contains('messages-wrapper')) {
          this.Hide();
        }
      },
      Esc(e) {
        if (e.keyCode == 27) {
          this.Hide();
        }
      }
    }
  }
</script>

<style scoped>

</style>
